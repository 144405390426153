import { useSearchParams } from "react-router-dom";
import { Button, Container, Icon, Message } from "semantic-ui-react";
import config from "../config";
import googleButton from "../images/btn_google_signin_dark_normal_web.png";
import logo from "../logos/sidekick.svg";

const style = {
  container: {
    paddingTop: "6rem",
    padding: "3rem",
    textAlign: "center",
  },
  logo: {
    width: "240px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: "4rem",
  },
  loginButtons: {
    margin: "auto",
    maxWidth: "280px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "24px",
  },
  loginButton: {
    width: "190px",
    paddingLeft: 0,
    paddingRight: 0,
    height: "40px",
  },
  loginImg: {
    cursor: "pointer",
  },
  info: {
    marginTop: "48px",
  },
  error: {
    color: "@red",
    marginTop: "48px",
  },
};

function Login() {
  const [searchParams] = useSearchParams();
  const err = searchParams.get("err");

  const getMsLoginUrl = () => {
    const url = `${config.api_url}/login/microsoft?spa_app_url=${config.app_url}&product=${config.product}`;

    if (config.customer === "next") {
      return url + "&type=next";
    }

    return url;
  };

  const getGoogleLoginUrl = () => {
    const url = `${config.api_url}/login/google?spa_app_url=${config.app_url}&product=${config.product}`;

    if (config.customer === "next") {
      return url + "&type=next";
    }

    return url;
  };

  return (
    <Container fluid style={style.container}>
      <div style={style.logo}>
        <img height="120" src={logo} alt="Logo" />
      </div>

      <div style={style.loginButtons}>
        <Button
          as="a"
          href={getMsLoginUrl()}
          style={{
            ...style.loginButton,
            background: "#00A4EF",
            color: "white",
          }}
        >
          <Icon name="windows" /> Sign in with Microsoft
        </Button>

        {config.customer !== "next" && (
          <a href={getGoogleLoginUrl()} style={style.loginImg}>
            <img src={googleButton} alt="Google Login" />
          </a>
        )}
      </div>

      {err === "ulExceeded" && (
        <Message negative compact>
          <p>Your organisation has exceeded it's user license allowance</p>
        </Message>
      )}

      <div style={style.info}>
        <a href={config.privacy_policy_url} target="_blank" rel="noreferrer">
          Privacy Policy
        </a>
      </div>
    </Container>
  );
}

export default Login;
