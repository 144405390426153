import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Button,
  ButtonGroup,
  Container,
  Dimmer,
  Form,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextArea,
  Header,
  Icon,
  Loader,
  Message,
  Modal,
  ModalActions,
  ModalContent,
  Progress,
  Segment,
} from "semantic-ui-react";
import {
  generateFile,
  generateScript,
  getMoh,
  getMusicPreviewUrl,
  getSoundOptions,
  getVoicePreviewUrl,
  uploadIpecs,
} from "../../api/moh";
import { useAuth } from "../../contexts/AuthContext";
import { useMoH } from "../../contexts/MoHContext";
import { style } from "../../style";

function CreateMoH() {
  const { id } = useParams();
  const [stepNumber, setStepNumber] = useState(0);
  const [name, setName] = useState("My new message");
  const [company, setCompany] = useState("");
  const [industry, setIndustry] = useState("");
  const [services, setServices] = useState("");
  const [openTimes, setOpenTimes] = useState("");
  const [tone, setTone] = useState("");
  const [other, setOther] = useState("");
  const [length, setLength] = useState("short");
  const [script, setScript] = useState("");
  const [voice, setVoice] = useState("");
  const [music, setMusic] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [generatedRes, setGeneratedRes] = useState();
  const { apiUser } = useAuth();


  const {
    website,
    companyName: defaultCompanyName,
    industry: defaultIndustry,
    services: defaultServices,
    openTimes: defaultOpenTimes,
    tone: defaultTone,
  } = useMoH();

  const navigate = useNavigate();

  const mohQuery = useQuery({
    queryKey: ["get-moh", id],
    queryFn: () => getMoh(id, { hydrate: 1 }),
    enabled: id ? true : false,
  });

  const soundOptionsQuery = useQuery({
    queryKey: ["get-sound-options"],
    queryFn: getSoundOptions,
  });

  useEffect(() => {
    if (id) {
      setName(mohQuery.data?.data?.data?.name);
      setCompany(mohQuery.data?.data?.data?.company_name);
      setIndustry(mohQuery.data?.data?.data?.industry);
      setServices(mohQuery.data?.data?.data?.services);
      setOpenTimes(mohQuery.data?.data?.data?.open_times);
      setTone(mohQuery.data?.data?.data?.tone);
      setLength(mohQuery.data?.data?.data?.length);
      setOther(mohQuery.data?.data?.data?.other);
      setScript(mohQuery.data?.data?.data?.script);
      setVoice(mohQuery.data?.data?.data?.voice);
      setMusic(mohQuery.data?.data?.data?.music);
      setGeneratedRes({
        id: mohQuery.data?.data?.data?.id,
        url: mohQuery.data?.data?.data?.url,
      });
      setStepNumber(3);
    } else {
      if (!website) {
        navigate("/admin/moh/getting-to-know-you");
      }
      setCompany(defaultCompanyName);
      setIndustry(defaultIndustry);
      setServices(defaultServices);
      setOpenTimes(defaultOpenTimes);
      setTone(defaultTone);
    }
  }, [
    website,
    navigate,
    id,
    mohQuery.data,
    defaultCompanyName,
    defaultIndustry,
    defaultServices,
    defaultOpenTimes,
    defaultTone,
  ]);

  const generateScriptMutation = useMutation({
    mutationFn: () => {
      setScript("");
      return generateScript({
        name: name,
        company: company,
        industry: industry,
        services: services,
        tone: tone,
        length: length,
        open_times: openTimes,
        other: other,
      });
    },
    onSuccess: (res) => {
      setScript(res.data?.data ?? "");
      setGeneratedRes();
      setStepNumber((prev) => prev + 1);
    },
    onError: (error) => {
      if (error?.response?.status === 422) {
        setFormErrors(error?.response?.data?.errors);
      } else {
        toast.error(
          "There was an error creating the script. Please try again.",
          {
            toastId: "generate-script-error",
          },
        );
      }
    },
  });

  const generateFileMutation = useMutation({
    mutationFn: () => {
      return generateFile({
        id: id,
        name: name,
        website: website,
        company: company,
        industry: industry,
        services: services,
        tone: tone,
        length: length,
        open_times: openTimes,
        other: other,
        script: script,
        music: music,
        voice: voice,
      });
    },
    onSuccess: (res) => {
      if (!id) {
        navigate(`/admin/moh/create/${res.data.data.id}`);
      } else {
        setGeneratedRes(res.data.data);
        setStepNumber((prev) => prev + 1);
      }
    },
    onError: (error) => {
      const message =
        error.response.data.message ??
        "There was an error generating the file. Please try again.";
      toast.error(message, {
        toastId: "generate-file-error",
      });
    },
  });

  const uploadIpecsMutation = useMutation({
    mutationFn: () => {
      return uploadIpecs({ id: generatedRes.id });
    },
    onSuccess: () => {
      toast.success("Successfully uploaded to iPECS");
    },
    onError: () => {
      toast.error("Failed to upload to iPECS");
    },
  });

  const handleChangeField = (name, value, func) => {
    const newErrors = { ...formErrors };
    delete newErrors[name];
    setFormErrors(newErrors);
    func(value);
  };

  const renderStep = (step) => {
    switch (step) {
      case 0:
        return renderScriptOptions();
      case 1:
        return renderScriptAmends();
      case 2:
        return renderAudioOptions();
      case 3:
        return renderDownload(apiUser);
      default:
        return <div>Error</div>;
    }
  };

  const renderScriptOptions = () => {
    return (
      <div>
        <Message
          color="black"
          icon="headphones"
          header="Generate your message"
          content={
            <>
              Let's get started by generating your message, we've already set
              some fields based on what we know about.{" "}
              <Link to="/admin/moh/getting-to-know-you">
                Click here to edit company infomration.
              </Link>
            </>
          }
        />

        <Form onSubmit={() => generateScriptMutation.mutate()}>
          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="company"
              label="Company Name"
              placeholder="What's your company name?"
              error={
                formErrors?.company
                  ? {
                      content: formErrors?.company[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={company}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setCompany)
              }
            />
            <FormInput
              required
              fluid
              name="industry"
              label="Industry"
              placeholder="What industry do you work in?"
              error={
                formErrors?.industry
                  ? {
                      content: formErrors?.industry[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={industry}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setIndustry)
              }
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="services"
              label="Services"
              placeholder="What services do you provide?"
              error={
                formErrors?.services
                  ? {
                      content: formErrors?.services[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={services}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setServices)
              }
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormInput
              required
              fluid
              name="open_times"
              label="Open Days & Hours"
              placeholder="What days and times are you open?"
              error={
                formErrors?.open_times
                  ? {
                      content: formErrors?.open_times[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={openTimes}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setOpenTimes)
              }
            />

            <FormInput
              required
              fluid
              name="tone"
              label="Tone"
              placeholder="The tone of your message"
              error={
                formErrors?.tone
                  ? {
                      content: formErrors?.tone[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={tone}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setTone)
              }
            />
          </FormGroup>

          <FormGroup widths="equal">
            <FormSelect
              required
              fluid
              label="Length of script"
              name="length"
              options={[
                { text: "Short", value: "short" },
                { text: "Medium", value: "medium" },
                { text: "Long", value: "long" },
              ]}
              error={formErrors?.length ? true : false}
              placeholder="Length of script"
              value={length}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setLength)
              }
            />
          </FormGroup>

          <FormTextArea
            label="Anything else?"
            name="other"
            placeholder="Anything else that you would like to add to the script? For example, product promotions or the seasonal information."
            value={other}
            onChange={(_, { name, value }) =>
              handleChangeField(name, value, setOther)
            }
          />

          <FormGroup widths="equal">
            <FormInput
              fluid
              name="name"
              required
              label="Name your message"
              placeholder="A short name you can use to identify this message later on"
              error={
                formErrors?.name
                  ? {
                      content: formErrors?.name[0] ?? "",
                      pointing: "above",
                    }
                  : false
              }
              value={name}
              onChange={(_, { name, value }) =>
                handleChangeField(name, value, setName)
              }
            />
          </FormGroup>

          <ActionButtonsContainer>
            <Button
              loading={generateScriptMutation.isLoading}
              type="submit"
              style={style.gradientButton}
            >
              Next
            </Button>
          </ActionButtonsContainer>
        </Form>
      </div>
    );
  };

  const renderScriptAmends = () => {
    return (
      <div>
        <Message
          color="black"
          icon="edit"
          header="Your message"
          content="Below is the script we've generated, you can make any amendments by editing the below text."
        />

        <Form>
          <FormGroup>
            <ScriptBox script={script} setScript={setScript} />
          </FormGroup>

          <ActionButtonsContainer>
            <ButtonGroup>
              <Button
                onClick={() => setStepNumber((prev) => prev - 1)}
                style={style.gradientButton}
              >
                Back
              </Button>
              <Button
                onClick={() => setStepNumber((prev) => prev + 1)}
                style={style.gradientButton}
              >
                Next
              </Button>
            </ButtonGroup>
          </ActionButtonsContainer>
        </Form>
      </div>
    );
  };

  const renderAudioOptions = () => {
    return (
      <div>
        <Message
          color="black"
          icon="music"
          header="Voice & Music Selection"
          content="Finish up by selecting the voice to read the message, and the music you'd like to be played."
        />

        <Form>
          <FormGroup widths="equal">
            <FormSelect
              required
              fluid
              label="Voice"
              name="voice"
              options={soundOptionsQuery.data.data.data.voices.map((x) => ({
                text: x,
                value: x,
              }))}
              placeholder="Voice selection"
              value={voice}
              onChange={(_, { value }) => setVoice(value)}
            />

            <VoicePreview voice={voice} />

            <FormSelect
              required
              fluid
              label="Music"
              name="voice"
              options={soundOptionsQuery.data.data.data.music.map((x) => ({
                text: x,
                value: x,
              }))}
              placeholder="Music selection"
              value={music}
              onChange={(_, { value }) => setMusic(value)}
            />

            <MusicPreview music={music} />
          </FormGroup>

          <ActionButtonsContainer>
            <ButtonGroup>
              <Button
                disabled={generateFileMutation.isLoading}
                onClick={() => setStepNumber((prev) => prev - 1)}
                style={style.gradientButton}
              >
                Back
              </Button>

              <Button
                disabled={!voice || !music}
                loading={generateFileMutation.isLoading}
                onClick={() => generateFileMutation.mutate()}
                style={style.gradientButton}
              >
                Save
              </Button>
            </ButtonGroup>
          </ActionButtonsContainer>
        </Form>
      </div>
    );
  };

  const renderDownload = (apiUser) => {
    return (
      <div>
        <Message
          color="black"
          icon="download"
          header="Download or Upload"
          content="Use the options below to listen, download or upload your music on hold."
        />

        <ButtonGroup>
          <GeneratedPreview url={generatedRes.url} />

          <Button
            disabled={
              generateFileMutation.isLoading || uploadIpecsMutation.isLoading
            }
            onClick={() => window.open(generatedRes.url)}
            style={style.purpleGradientButton}
            labelPosition="left"
            icon
          >
            <Icon name="download" />
            Download
          </Button>

          {apiUser?.sidekick_configured && (
            <SendToIpecs
              disabled={
                generateFileMutation.isLoading || uploadIpecsMutation.isLoading
              }
              loading={uploadIpecsMutation.isLoading}
              onConfirm={() => uploadIpecsMutation.mutate()}
            />
          )}
        </ButtonGroup>

        <ActionButtonsContainer>
          <ButtonGroup>
            <Button
              onClick={() => setStepNumber((prev) => prev - 1)}
              style={style.gradientButton}
            >
              Edit
            </Button>
          </ButtonGroup>
        </ActionButtonsContainer>
      </div>
    );
  };

  if (id && mohQuery.isLoading) return null;
  if (soundOptionsQuery.isPending) return null;

  return (
    <Container
      style={{
        margin: "20px 0px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <Breadcrumb>
        <BreadcrumbSection>Admin</BreadcrumbSection>
        <BreadcrumbDivider />
        <BreadcrumbSection>
          <Link to="/admin/moh">MoH</Link>
        </BreadcrumbSection>
        <BreadcrumbDivider icon="right angle" />
        <BreadcrumbSection active>
          {stepNumber === 3 ? "View MoH" : "Create MoH"}
        </BreadcrumbSection>
      </Breadcrumb>

      <Header as="h2">Music on Hold</Header>

      {stepNumber < 3 && (
        <Segment>
          <Progress
            value={stepNumber + 1}
            total={3}
            attached="top"
            color="blue"
            active
          />
          Step {stepNumber + 1} of 3
          {(generateScriptMutation.isLoading ||
            generateFileMutation.isLoading) && (
            <Dimmer active>
              <Loader />
            </Dimmer>
          )}
          <Progress value={stepNumber + 1} total={3} attached="bottom" />
        </Segment>
      )}

      {renderStep(stepNumber)}
    </Container>
  );
}

function VoicePreview({ voice }) {
  const [playingVoice, setPlayingVoice] = useState(voice);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
    setPlayingVoice(voice);
  }, [voice]);

  const previewRef = useRef();

  const handleClick = async () => {
    try {
      previewRef.current.src = getVoicePreviewUrl(voice);
      previewRef.current.currentTime = 0;
      playing ? previewRef.current.pause() : previewRef.current.play();
      setPlaying(!playing);
    } catch (e) {
      alert("Failed to preview");
    }
  };

  return (
    <>
      <Button
        disabled={!playingVoice}
        style={{ alignSelf: "flex-end" }}
        primary
        icon
        onClick={handleClick}
      >
        <Icon name={playing ? "pause" : "play"} />
      </Button>

      <audio ref={previewRef} onEnded={() => setPlaying(false)} />
    </>
  );
}

function MusicPreview({ music }) {
  const [playingMusic, setPlayingMusic] = useState(music);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
    setPlayingMusic(music);
  }, [music]);

  const previewRef = useRef();

  const handleClick = async () => {
    try {
      previewRef.current.src = getMusicPreviewUrl(music);
      previewRef.current.currentTime = 0;
      playing ? previewRef.current.pause() : previewRef.current.play();
      setPlaying(!playing);
    } catch (e) {
      alert("Failed to preview");
    }
  };

  return (
    <>
      <Button
        disabled={!playingMusic}
        style={{ alignSelf: "flex-end" }}
        primary
        icon
        onClick={handleClick}
      >
        <Icon name={playing ? "pause" : "play"} />
      </Button>

      <audio ref={previewRef} src={`/moh/music/${playingMusic}.wav`} />
    </>
  );
}

function ScriptBox({ script, setScript }) {
  const [currentScript, setCurrentScript] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disabled, setDisabled] = useState(true);

  const take = 10;

  const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  useEffect(() => {
    if (currentIndex < script.length) {
      setDisabled(true);
      const delay = randomIntFromInterval(10, 50);
      const timeout = setTimeout(() => {
        setCurrentScript(
          (prev) => prev + script.slice(currentIndex, currentIndex + take),
        );
        setCurrentIndex((prevIndex) => prevIndex + take);
      }, delay);

      return () => clearTimeout(timeout);
    } else {
      setDisabled(false);
    }
  }, [currentIndex, script]);

  return (
    <TextareaAutosize
      maxLength={1800}
      readOnly={disabled}
      name="script"
      placeholder="Your script goes here"
      value={currentScript}
      onChange={(e) => {
        setScript(e.target.value);
        setCurrentScript(e.target.value);
      }}
    />
  );
}

function GeneratedPreview({ url }) {
  const [playingUrl, setPlayingUrl] = useState(url);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    setPlaying(false);
    setPlayingUrl(url);
  }, [url]);

  const previewRef = useRef();

  const handleClick = () => {
    previewRef.current.currentTime = 0;
    playing ? previewRef.current.pause() : previewRef.current.play();
    setPlaying(!playing);
  };

  return (
    <>
      <Button
        style={style.purpleGradientButton}
        disabled={!playingUrl}
        labelPosition="left"
        icon
        onClick={handleClick}
      >
        <Icon name={playing ? "pause" : "play"} />
        Preview
      </Button>

      <audio ref={previewRef} src={playingUrl} />
    </>
  );
}

function ActionButtonsContainer({ children }) {
  return (
    <div style={{ marginTop: "48px", marginBottom: "24px" }}>{children}</div>
  );
}

function SendToIpecs({ disabled, loading, onConfirm }) {
  const [open, setOpen] = useState(false);

  const handleConfirm = () => {
    setOpen(false);
    onConfirm();
  };

  return (
    <>
      <Button
        disabled={disabled}
        loading={loading}
        onClick={() => setOpen(true)}
        style={style.purpleGradientButton}
        labelPosition="left"
        icon
      >
        <Icon name="upload" />
        Send to iPECS
      </Button>
      <Modal
        centered={false}
        closeIcon
        onClose={() => setOpen(false)}
        open={open}
      >
        <Header>Send to iPECS</Header>
        <ModalContent>
          This will override all existing hold music on your iPECS system, are
          you sure?
        </ModalContent>
        <ModalActions>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={handleConfirm}
            style={style.purpleGradientButton}
            labelPosition="left"
            icon
          >
            <Icon name="upload" />
            Confirm
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
}

export default CreateMoH;
