import {
  BrowserRouter,
  Link,
  matchPath,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Icon, Menu } from "semantic-ui-react";
import Topbar from "./components/Topbar";
import { useAuth } from "./contexts/AuthContext";
import { MoHProvider } from "./contexts/MoHContext";
import layout from "./layout";
import AddTeam from "./screens/AddTeam";
import AddUser from "./screens/AddUser";
import ConfigureSidekick from "./screens/ConfigureSidekick";
import Leaderboard from "./screens/Leaderboard";
import Login from "./screens/Login";
import CreateMoH from "./screens/moh/CreateMoH";
import ListMoH from "./screens/moh/ListMoH";
import GettingToKnowYou from "./screens/moh/GettingToKnowYou";
import NoAccess from "./screens/NoAccess";
import NotFound from "./screens/NotFound";
import Sidekick from "./screens/Sidekick";
import TeamAdmin from "./screens/TeamAdmin";
import UpdateTeam from "./screens/UpdateTeam";
import UserAdmin from "./screens/UserAdmin";
import UserDetails from "./screens/UserDetails";

const style = {
  wrapper: {
    minHeight: "100%",
  },
  app: {
    display: "flex",
  },
  sidebar: {
    minHeight: `calc(100vh - ${layout.topbarHeight})`,
    display: "flex",
    borderLeft: "none",
    borderTop: "none",
    borderBottom: "none",
    borderRadius: 0,
    minWidth: layout.sidebarWidth,
  },
  content: {
    width: "100%",
    height: `calc(100vh - ${layout.topbarHeight}`,
    overflowY: "scroll",
  },
};

const AppRoutes = () => {
  const { apiUser, hasFeature } = useAuth();

  const unauthenticatedRoutes = () => {
    return (
      <>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </>
    );
  };

  const authenticatedRoutes = () => {
    if (!apiUser?.has_sidekick && !apiUser?.has_moh) {
      return (
        <Route path="/" element={<AuthLayout />}>
          <Route index element={<NoAccess />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      );
    }

    return (
      <Route path="/" element={<AuthLayout />}>
        {apiUser?.has_sidekick &&
          (!apiUser?.sidekick_configured ? (
            <>
              <Route
                exact
                path="/"
                element={<ConfigureSidekick adminUser={apiUser.is_admin} />}
              />
              <Route path="*" element={<Navigate to="/" />} />
            </>
          ) : (
            <>
              <Route index element={<Sidekick />} />
              {(apiUser?.is_admin || hasFeature(["sidekick_supervisor"])) && (
                <Route path="/admin/leaderboard" element={<Leaderboard />} />
              )}
              {apiUser?.is_admin && (
                <>
                  <Route path="/admin/teams/create" element={<AddTeam />} />
                  <Route path="/admin/teams/:teamId" element={<UpdateTeam />} />
                  <Route path="/admin/teams" element={<TeamAdmin />} />
                  <Route path="/admin/users/create" element={<AddUser />} />
                  <Route
                    path="/admin/users/:userId"
                    element={<UserDetails />}
                  />
                  <Route path="/admin/users" element={<UserAdmin />} />
                  <Route
                    path="/admin"
                    element={<Navigate to="/admin/teams" />}
                  />
                </>
              )}
            </>
          ))}

        {apiUser?.has_moh && (
          <Route
            element={
              <MoHProvider>
                <Outlet />
              </MoHProvider>
            }
          >
            {!apiUser?.has_sidekick && (
              <Route index element={<Navigate to='/admin/moh' />} />
            )}
            <Route path="/admin/moh" element={<ListMoH />} />
            <Route path="/admin/moh/create" element={<CreateMoH />} />
            <Route path="/admin/moh/create/:id" element={<CreateMoH />} />
            <Route
              path="/admin/moh/getting-to-know-you"
              element={<GettingToKnowYou />}
            />
          </Route>
        )}

        <Route path="*" element={<NotFound />} />
      </Route>
    );
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          {apiUser ? authenticatedRoutes() : unauthenticatedRoutes()}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

function Wrapper() {
  return (
    <div style={style.wrapper}>
      <Outlet />
    </div>
  );
}

function AuthLayout() {
  const { apiUser, hasFeature } = useAuth();

  return (
    <>
      <Topbar />
      <div style={style.app}>
        {(apiUser?.has_sidekick || apiUser.has_moh) && (
          <Menu
            inverted
            borderless
            icon="labeled"
            compact
            vertical
            style={style.sidebar}
          >
            <div style={{ flexGrow: "1" }}>
              {apiUser?.has_sidekick && (
                <>
                  <MenuItem to="/">
                    <Icon name="talk" />
                    Recordings
                  </MenuItem>
                  {(apiUser?.is_admin ||
                    hasFeature(["sidekick_supervisor"])) && (
                    <MenuItem to="/admin/leaderboard">
                      <Icon name="chart line" />
                      Leaderboard
                    </MenuItem>
                  )}
                  {apiUser?.is_admin && (
                    <>
                      <MenuItem to="/admin/users">
                        <Icon name="user" />
                        Users
                      </MenuItem>
                      <MenuItem to="/admin/teams">
                        <Icon name="users" />
                        Teams
                      </MenuItem>
                    </>
                  )}
                </>
              )}

              {apiUser?.has_moh && (
                <MenuItem to="/admin/moh">
                  <Icon name="music" />
                  MoH
                </MenuItem>
              )}
            </div>
          </Menu>
        )}
        <div style={style.content}>
          <Outlet />
        </div>
      </div>
    </>
  );
}

function MenuItem({ to, children }) {
  const { pathname } = useLocation();

  return (
    <Menu.Item
      as={Link}
      to={to}
      active={matchPath(`${to}/*`, pathname) ? true : false}
    >
      {children}
    </Menu.Item>
  );
}

export default AppRoutes;
