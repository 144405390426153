import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Container,
} from "semantic-ui-react";
import { getTeam } from "../api/teams";
import PageLoader from "../components/PageLoader";
import ManageTeam from "../components/sidekick/teams/ManageTeam";

const UpdateTeam = () => {
  const { teamId } = useParams();

  const teamMembersQuery = useQuery(
    ["team-members", teamId],
    () => {
      return getTeam(teamId);
    },
    {
      keepPreviousData: true,
      staleTime: 60000,
    },
  );

  if (teamMembersQuery.isLoading) return <PageLoader />;

  console.log(teamMembersQuery);

  return (
    <>
      <Container
        style={{
          margin: "20px 0px",
          display: "flex",
          gap: "25px",
          flexDirection: "column",
        }}
      >
        <Breadcrumb>
          <BreadcrumbSection>Admin</BreadcrumbSection>
          <BreadcrumbDivider />
          <BreadcrumbSection>
            <Link to="/admin/teams">Teams</Link>
          </BreadcrumbSection>
          <BreadcrumbDivider icon="right angle" />
          <BreadcrumbSection active>Manage team</BreadcrumbSection>
        </Breadcrumb>

        <ManageTeam data={teamMembersQuery?.data?.data?.data} />
      </Container>
    </>
  );
};

export default UpdateTeam;
