import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  ButtonGroup,
  ButtonOr,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Container,
  Form,
  FormField,
  Header,
  Icon,
  Image,
  Input,
  Label,
  Modal,
  ModalActions,
  ModalContent,
  ModalHeader
} from "semantic-ui-react";
import {
  changeParticipantName,
  emailTranscription,
  getCallRecordingTranscriptions,
  summariseExistingTranscription,
} from "../../api/transcriptions";
import sidekickLogoWhite from "../../images/sidekick-logo-white-75x79.png";
import { style } from "../../style";
import { copyRichText } from "../../utils/copyRichText";
import { getSentimentIcon } from "../../utils/getSentimentIcon";
import PageLoader from "../PageLoader";

const CallInfoModal = ({ infoModal, setInfoModal }) => {
  const [editing, setEditing] = useState();
  const queryClient = useQueryClient();
  const summaryQuery = useQuery(
    ["call-summary", "hydrate", infoModal.id],
    () => {
      return getCallRecordingTranscriptions(infoModal.id, { hydrate: "1" });
    },
    {
      keepPreviousData: false,
      staleTime: 60000,
      // Disable unless we're passing a call ID, otherwise it'll make an unneccessary API call on mount
      enabled: !!infoModal.id,
      refetchInterval: (data) => {
        let interval = false;
        if (["SUMMARISING"].includes(data?.data?.data?.status?.value)) {
          interval = 30 * 1000;
        }
        return interval;
      },
    },
  );

  if (summaryQuery.isError) {
    toast("Unable to get call details", { type: "error" });
    setInfoModal({ open: false, id: "", tab: "summary" });
  }

  const changeNameMutation = useMutation({
    mutationFn: (input) => {
      return changeParticipantName(input);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["call-summary", "hydrate"]);
      setEditing();
    },
    onError: (e) => {
      if (e.response.status !== 422) {
        toast.error("Failed to change participant name. Please try again.");
      }
    },
  });

  return (
    <InfoModalContent
      infoModal={infoModal}
      setInfoModal={setInfoModal}
      isLoading={
        summaryQuery.isLoading ||
        summaryQuery.isFetching ||
        changeNameMutation.isLoading
      }
      data={summaryQuery?.data?.data?.data}
    >
      {infoModal.tab === "summary" ? (
        <Summary data={summaryQuery?.data?.data?.data} />
      ) : (
        <Transcription
          changeNameMutation={changeNameMutation}
          data={summaryQuery?.data?.data?.data}
          editing={editing}
          setEditing={setEditing}
        />
      )}
    </InfoModalContent>
  );
};

const InfoModalContent = ({
  children,
  data,
  infoModal,
  setInfoModal,
  isLoading,
}) => {
  const queryClient = useQueryClient();
  const [summaryIsLoading, setSummaryIsLoading] = useState(
    data?.status.value === "SUMMARISING",
  );

  const handleError = (error) => {
    toast(
      error.response?.data?.message ?? "There was an error with your request",
      {
        type: "error",
        autoClose: 10000,
        toastId: "sidekick-limit-error",
      },
    );
  };

  return (
    <Modal
      centered={false}
      closeIcon
      onClose={() => setInfoModal({ open: false, id: "", tab: "summary" })}
      open={infoModal.open}
    >
      <Header>
        <ButtonGroup>
          <Button
            style={
              infoModal.tab === "transcription"
                ? style.gradientButton
                : style.gradientButtonInactive
            }
            onClick={() => setInfoModal({ ...infoModal, tab: "transcription" })}
          >
            Transcription
          </Button>
          {data?.has_summary && (
            <Button
              style={
                infoModal.tab === "summary"
                  ? {
                      ...style.purpleGradientButton,
                      ...style.modalSummaryButton,
                    }
                  : {
                      ...style.gradientButtonInactive,
                      ...style.modalSummaryButton,
                    }
              }
              active={infoModal.tab === "summary"}
              onClick={() => setInfoModal({ ...infoModal, tab: "summary" })}
            >
              <>
                <Image
                  src={sidekickLogoWhite}
                  alt="Sidekick logo"
                  style={{ width: "20px" }}
                />
                AI
              </>
            </Button>
          )}
        </ButtonGroup>
      </Header>
      <ModalContent>
        {isLoading ? <PageLoader>Loading...</PageLoader> : children}
      </ModalContent>
      <ModalActions>
        <div style={{ display: "flex", gap: "15px", justifyContent: "end" }}>
          {infoModal.tab === "transcription" &&
            !data?.has_summary &&
            data?.can_summarise && (
              <Button
                style={{
                  ...style.purpleGradientButton,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: "8px 21px",
                  width: "110px",
                  justifyContent: "space-around",
                }}
                onClick={async () => {
                  setSummaryIsLoading(true);
                  try {
                    await summariseExistingTranscription(data.sqid);
                    queryClient.invalidateQueries({
                      queryKey: ["call-summary", "hydrate", data.sqid],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["call-transcriptions"],
                    });
                    queryClient.invalidateQueries({
                      queryKey: ["call-recordings"],
                    });
                  } catch (e) {
                    setSummaryIsLoading(false);
                    handleError(e);
                  }
                }}
                loading={summaryIsLoading}
              >
                {!summaryIsLoading && (
                  <>
                    <Image
                      src={sidekickLogoWhite}
                      alt="Sidekick logo"
                      style={{ width: "20px" }}
                    />
                    AI
                  </>
                )}
              </Button>
            )}
          <EmailButton data={data} />
          <CopyButton />
        </div>
      </ModalActions>
    </Modal>
  );
};

const Summary = ({ data }) => {
  const sentimentIcon = getSentimentIcon(data?.sentiment?.rating);
  return (
    <Container id="content">
      {data?.sentiment && (
        <>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Icon
              name={sentimentIcon.name}
              size="big"
              style={{ color: sentimentIcon.color }}
            />
            <p>{data?.sentiment?.summary}</p>
          </div>
        </>
      )}
      <Header as="h2">SUMMARY</Header>
      <div>
        {data?.can_summarise ? (
          <>
            <p>{data?.summary}</p>
          </>
        ) : (
          data?.summary
        )}
      </div>
      {data?.meeting_notes && (
        <>
          <Header as="h2">MEETING NOTES</Header>
          <div>
            {!data.meeting_notes
              ? "No meeting notes available."
              : data?.meeting_notes?.map((i) => (
                  <React.Fragment key={i.header}>
                    <Header as="h3">{i.header}</Header>
                    <ul>
                      {i.content.map((note) => (
                        <li key={note}>{note}</li>
                      ))}
                    </ul>
                  </React.Fragment>
                ))}
          </div>
        </>
      )}
    </Container>
  );
};

const Transcription = ({ changeNameMutation, data, editing, setEditing }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <Container id="content">
      <Header as="h2">TRANSCRIPTION</Header>
      {(data?.transcription?.conversation ?? []).map((x, i) => (
        <Card
          fluid
          key={i}
          style={{
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: x.color ?? "rgba(0,0,0,0)",
          }}
        >
          <CardContent>
            {!changeNameMutation.isLoading && editing?.index === i ? (
              <Form>
                <FormField>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "3px",
                      marginBottom: "1rem",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        gap: "10px",
                      }}
                    >
                      <small>
                        <Icon name="help circle" /> You can either change all
                        occurences of the name '{editing?.original_value}' in
                        the transcript, or change it just for this segment.
                      </small>
                      <Input
                        placeholder={`e.g. ${x.participant}`}
                        style={{ maxWidth: "18rem" }}
                        value={editing?.new_value}
                        onChange={(_, { value }) =>
                          setEditing({ ...editing, new_value: value })
                        }
                      />
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        <ButtonGroup>
                          <Button
                            color="violet"
                            onClick={() =>
                              changeNameMutation.mutate({
                                ...editing,
                                change: "all",
                                sqid: data.sqid,
                              })
                            }
                          >
                            Change all
                          </Button>
                          <ButtonOr />
                          <Button
                            color="blue"
                            onClick={() =>
                              changeNameMutation.mutate({
                                ...editing,
                                change: i,
                                sqid: data.sqid,
                              })
                            }
                          >
                            Change this one
                          </Button>
                        </ButtonGroup>
                        <Button
                          color="grey"
                          onClick={() => {
                            setEditing();
                            changeNameMutation.reset();
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    {changeNameMutation?.error?.response?.data?.errors
                      .new_value[0] && (
                      <Label color="red" style={{ width: "fit-content" }}>
                        {
                          changeNameMutation?.error?.response?.data?.errors
                            ?.new_value[0]
                        }
                      </Label>
                    )}
                  </div>
                </FormField>
              </Form>
            ) : (
              <CardHeader
                onMouseEnter={() => setHoveredIndex(i)}
                onMouseLeave={() => setHoveredIndex(null)}
                style={{ display: "flex", alignContent: "center", gap: "8px" }}
              >
                {x.participant}
                {hoveredIndex === i && (
                  <Button
                    icon
                    disabled={changeNameMutation.isLoading}
                    size="small"
                    color="teal"
                    style={{
                      backgroundColor: "transparent",
                      padding: 0,
                    }}
                    onClick={() =>
                      setEditing({
                        index: i,
                        original_value: x.participant,
                        new_value: x.participant,
                      })
                    }
                  >
                    <Icon name="edit" style={{ width: "30px" }} />
                  </Button>
                )}
              </CardHeader>
            )}
            <CardDescription>{x.content}</CardDescription>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
};

const CopyButton = () => {
  return (
    <Button
      icon
      labelPosition="left"
      color="teal"
      onClick={() => {
        const el = document.getElementById("content");
        copyRichText(el);
      }}
    >
      <Icon name="copy" />
      Copy
    </Button>
  );
};

const EmailButton = ({ data }) => {
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    try {
      await emailTranscription(data.sqid, { to_email: email });
      toast.success(`Email sent to ${email}`);
      setEmailModalOpen(false);
      setEmail("");
    } catch (error) {
      if (
        error.response?.status === 422 &&
        error.response?.data?.errors?.to_email
      ) {
        setError(error.response.data.errors.to_email[0]);
      } else {
        toast.error("Failed to send email. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const closeModal = () => {
    setEmailModalOpen(false);
    setError(null);
  };

  return (
    <>
      <Button
        icon
        labelPosition="left"
        color="teal"
        onClick={() => setEmailModalOpen(true)}
      >
        <Icon name="mail" />
        Email
      </Button>

      <Modal size="tiny" open={emailModalOpen} onClose={closeModal}>
        <ModalHeader>Email call summary</ModalHeader>
        <ModalContent>
          <p>
            We'll send a copy of the call transcript and summary to the email
            address you enter, and BCC you so that you have a record of what's
            sent.
          </p>
          <Form>
            <FormField>
              <label>Email address</label>
              <Input
                placeholder="Enter email address"
                value={email}
                onChange={(_, { value }) => {
                  setError(null);
                  setEmail(value);
                }}
                error={!!error}
              />
              {error && (
                <Label color="red" pointing>
                  {error}
                </Label>
              )}
            </FormField>
          </Form>
        </ModalContent>
        <ModalActions>
          <Button onClick={closeModal}>Cancel</Button>
          <Button
            color="teal"
            onClick={handleSubmit}
            loading={isSubmitting}
            disabled={!email}
          >
            Send
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default CallInfoModal;
